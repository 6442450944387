import "./i18n";
import "./App.css";
import Route from './Routes';

function App() {
  return (
    <>
       <Route />
    </>
  );
}

export default App;
