import React from 'react'
import Body from './common/Body'
import imgservice2 from "../assets/images/img-service-2.png"
import imgservice3 from "../assets/images/img-service-3.png"
import imgservice4 from "../assets/images/img-service-4.png"
import { useTranslation } from 'react-i18next'

const Service = () => {
    const { t } = useTranslation()
    return (
        <Body>
            <section className="belford-service-hero-section" id="belford-service-hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="belford-service-hero-content">
                                <h1>{t("serviceData.heroSection.H1Text.Text")}<span>{t("serviceData.heroSection.H1Text.SpanText")}</span></h1>
                                <a href="/https://belfordwoodsschool.com/register" className="belford-btn">{t("serviceData.heroSection.belfordbtn.Text")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-service-course-section" id="belford-service-course-section">
                <div className="container">
                    <div className="row gy-4 gy-lg-0 align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="belford-service-course-img">
                                <img src={imgservice2} alt='imgservice2' className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="belford-service-course-box">
                                <h2><span>{t("serviceData.courseSection.H2Text.SpanText")}</span>{t("serviceData.courseSection.H2Text.Text")}</h2>
                                <p>{t("serviceData.courseSection.PText")}</p>
                                <ul className="belford-service-course-list">
                                    <li>{t("serviceData.courseSection.courselist.list1")}</li>
                                    <li>{t("serviceData.courseSection.courselist.list2")}</li>
                                    <li>{t("serviceData.courseSection.courselist.list3")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-service-further-section" id="belford-service-further-section">
                <div className="container">
                    <div className="row gy-4 gy-lg-0 align-items-center">
                        <div className="col-12 col-lg-6">
                            <div className="belford-service-further-box">
                                <div className="belford-service-further-img">
                                    <img src={imgservice3} alt='imgservice3' />
                                </div>
                                <div className="belford-service-further-content">
                                    <h2><span>{t("serviceData.furtherSection.furtherbox.Box1.H2Text.SpanText")}</span></h2>
                                    <p>{t("serviceData.furtherSection.furtherbox.Box1.PText")}</p>
                                    <a href="/https://belfordwoodsschool.com/register" className="belford-btn">{t("serviceData.furtherSection.furtherbox.Box2.belfordbtn.Text")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="belford-service-further-box">
                                <div className="belford-service-further-img">
                                    <img src={imgservice4} alt='imgservice4' />
                                </div>
                                <div className="belford-service-further-content">
                                    <h2><span>{t("serviceData.furtherSection.furtherbox.Box2.H2Text.SpanText")}</span></h2>
                                    <p>{t("serviceData.furtherSection.furtherbox.Box2.PText")}</p>
                                    <a href="/https://belfordwoodsschool.com/register" className="belford-btn">{t("serviceData.furtherSection.furtherbox.Box2.belfordbtn.Text")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Service