import React from 'react'
import Body from './common/Body'

const Withdraw = () => {
    return (
        <Body>
            <section className="belford-withdraw-hero-section" id="belford-withdraw-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="belford-withdraw-hero-content text-center">
                                <ul className="belford-tab-bar">
                                    <li> <a href="/" className="active">Welcome</a> </li>
                                    <li> <a href="/">Units</a> </li>
                                    <li> <a href="/">Calendar</a> </li>
                                    <li> <a href="/">Regulations</a> </li>
                                    <li> <a href="/">Contact Us</a> </li>
                                </ul>
                                <h1><span className="belford-title-color">Terminate</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-withdraw-about-section" id="belford-withdraw-about-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="belford-withdraw-about-content text-center">
                                <p>By clicking on “Terminate Now”, you are unsubscribing from this service. Payments starting from the following relevant month will not be charged. You will be able to enter your personal area until the end of the free week trial or the end of the already paid month.</p>
                                <a href="https://belfordwoodsschool.com/register" className="belford-btn withdraw-btn">Terminate Now </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Withdraw