/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { headerRouterConfig } from "./../../Routes/allRoutes";
import { common } from "./../../constant/commonData";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../../helper/Authorization";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import { API } from "../../helper/api";
const api = new API();

const Header = () => {
  const { t, i18n } = useTranslation();
  const headerData = common.header;
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  // const [isOpenLan, setIsOpenLan] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);

  useEffect(() => {
    console.log(navigator.geolocation);
    if (navigator.geolocation) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const detectLanguage = (country) => {
    const userCountryCode = country || "IT";
    console.log({userCountryCode});
    const countryLanguageMap = {
      US: "en",
      IT: "it",
      FR: "fr",
      ES: "es",
    };

    // console.log(isSessionItemEmpty('lag'));
    if(!isSessionItemEmpty('lag')){
      changeLanguageHandler(sessionStorage.getItem('lag'))
    }else if (countryLanguageMap[userCountryCode]) {
      changeLanguageHandler(countryLanguageMap[userCountryCode]);
    } else {
      console.log(1);
      changeLanguageHandler("en");
    }
  };

  const successCallback = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    try {
      api
        .getCountry("GEOCODE", latitude, longitude)
        .then((data) => {
          const countryIsoCode = data.countryCode;
          detectLanguage(countryIsoCode);
        })
        .catch((error) => {
          console.error("Error fetching geolocation data:", error);
        });
    } catch (error) {
      console.error("Error while get Units", error);
      toast.error(error.data.message);
    }

    // Call an API to get country code from latitude and longitude
    // fetch(
    //   ``
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const countryIsoCode = data.countryCode;
    //     console.log(countryIsoCode);
    //     detectLanguage(countryIsoCode);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching geolocation data:", error);
    //   });
  };

  // Function to handle geolocation errors
  const errorCallback = (error) => {
    console.error("Error retrieving geolocation:", error.message);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // const toggleDropdownLan = () => {
  //   setIsOpenLan(!isOpenLan);
  // };

  const toggleDropdownMobile = () => {
    setIsOpenMobile(!isOpenMobile);
    console.log(isOpenMobile);
  };

  const logout = () => {
    toast.success("Logout Successfully", { autoClose: 3000 });
    sessionStorage.removeItem("auth_token");
    sessionStorage.clear();
    navigate("/login");
  };

  const changeLanguageHandler = (language) => {
    sessionStorage.setItem('lag', language);
    i18n.changeLanguage(language);
  };

  const isSessionItemEmpty = (key) => {
      const item = sessionStorage.getItem(key);
      return item === null || item === '';
  }

  return (
    <header
      id="header"
      className={`header belford-header ${isScrolled ? "scroll" : ""}`}
    >
      <ToastContainer closeButton={false} limit={1} />
      <div className="belford-header-top">
        <div className="container testclass">
          <div className="belford-header-list">
            <ul>
              <li>
                <a href="#" onClick={() => changeLanguageHandler("en")}>
                  {headerData["language"][0]["EN"]}
                </a>
              </li>
              <li>
                <a href="#" onClick={() => changeLanguageHandler("es")}>
                  {headerData["language"][0]["ES"]}
                </a>
              </li>
              <li>
                <a href="#" onClick={() => changeLanguageHandler("fr")}>
                  {headerData["language"][0]["FR"]}
                </a>
              </li>
              <li>
                <a href="#" onClick={() => changeLanguageHandler("it")}>
                  {headerData["language"][0]["IT"]}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg active-nav fixed-top">
        <div className="container">
          <div className="belford-header-menu">
            <Link className="navbar-brand" to="/">
              <img src={logo} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleDropdownMobile}
              aria-expanded={isOpen ? "true" : "false"}
            >
              <span className="navbar-toggler-icon text-danger">
                {isOpenMobile ? (
                  <i class="bi bi-x"></i>
                ) : (
                  <i className="bi bi-justify"></i>
                )}
              </span>
            </button>
            <div
              id="navbarNav"
              className={`collapse navbar-collapse ${
                isOpenMobile ? "show" : ""
              }`}
            >
              <ul className="navbar-nav navbar-right ms-auto">
                {headerRouterConfig.map((route, index) => (
                  <React.Fragment key={index}>
                    {route?.children ? (
                      <li className="nav-item dropdown">
                        <NavLink
                          className={`nav-link dropdown-toggle ${route.label}`}
                          data-bs-toggle="dropdown"
                          role="button"
                          onClick={toggleDropdown}
                          aria-expanded={isOpen ? "true" : "false"}
                          to="#"
                        >
                          {t(`header.${route.label}`)}
                        </NavLink>
                        <ul className={`dropdown-menu ${isOpen ? "show" : ""}`}>
                          {route.children.map((childRoute, childIndex) => (
                            <li key={childIndex}>
                              <NavLink
                                className="dropdown-item"
                                to={`${route.path}/${childRoute.path}`}
                              >
                                {t(`header.${childRoute.label}`)}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item" key={index}>
                        <NavLink
                          className={({ isActive, isPending }) =>
                            isPending
                              ? "nav-link"
                              : isActive
                              ? "nav-link active"
                              : ""
                          }
                          to={route.path}
                        >
                          {t(`header.${route.label}`)}
                        </NavLink>
                      </li>
                    )}
                  </React.Fragment>
                ))}
                <li class="nav-item dropdown menu-extra-items">
                  <div className="belford-header-list-mobile">
                    <ul>
                      <li>
                        <a href="#" onClick={() => changeLanguageHandler("en")}>
                          {headerData["language"][0]["EN"]}
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => changeLanguageHandler("es")}>
                          {headerData["language"][0]["ES"]}
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => changeLanguageHandler("fr")}>
                          {headerData["language"][0]["FR"]}
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => changeLanguageHandler("it")}>
                          {headerData["language"][0]["IT"]}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                {isAuthenticated() ? (
                  <>
                    <li className="text-center auth-menu menu-extra-items">
                      {" "}
                      <a
                        href="/welcome"
                        className="belford-btn w-100 pt-2 pb-2"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li className="text-center auth-menu menu-extra-items">
                      <a
                        onClick={() => logout()}
                        className="belford-btn w-100 pt-2 pb-2"
                      >
                        Logout
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="text-center auth-menu menu-extra-items">
                      <a href="/login" className="belford-btn w-100 pt-2 pb-2">
                        {t("header.headerBtn.signin")}
                      </a>
                    </li>
                    <li className="text-center auth-menu menu-extra-items">
                      <a
                        href="/register"
                        className="belford-btn w-100 pt-2 pb-2"
                      >
                        {t("header.headerBtn.signup")}
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="belford-header-box">
            {/* <div className="belford-social-icon">
              <ul>
                <li>
                  <a href={socialLink.facebook}>
                    <img src={img2} />
                  </a>
                </li>
                <li>
                  <a href={socialLink.instagram}>
                    <img src={img3} />
                  </a>
                </li>
                <li>
                  <a href={socialLink.linkedin}>
                    <img src={img4} />
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="belford-header-btn">
              {isAuthenticated() ? (
                <>
                  <a href="/welcome">Dashboard</a>
                  <a onClick={() => logout()}>Logout</a>
                </>
              ) : (
                <>
                  <a href="/login">{t("header.headerBtn.signin")}</a>
                  <a href="/register" className="fill">
                    {t("header.headerBtn.signup")}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
