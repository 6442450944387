import React from 'react'
import Body from './common/Body'
import PrivateMenu from './common/PrivateMenu';

const Regulations = () => {
    return (
        <Body>
            <section className="belford-withdraw-hero-section" id="belford-withdraw-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="belford-withdraw-hero-content text-center">
                                <PrivateMenu />
                                <h1><span className="belford-title-color">Regulations</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-withdraw-about-section" id="belford-withdraw-about-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="belford-withdraw-about-content">

                                <h3>EN – Rules of the platform</h3>
                                <p>With the word “Platform” it is intended the section reserved to students who are registered on the website using the relevant form containing the whole English course related to the level chosen by the student.</p>
                                <p>With the word “Unit” it is intended the training module on different levels and including grammar, vocabulary, writing and reading elements.</p>
                                <p>For Golden Ribbon users, in the first 7-day free trial week, upon first login the user can view the first Unit of the chosen level. Upon completion of the first Unit, the second is automatically unlocked. During the free 7-day trial week, the user will only have access to two Units. In the first free trial week, the user can book a free individual lesson with a dedicated teacher which must be held within such  trial week. If the user does not book the one-to-one lesson within the aforementioned deadline, the free lesson cannot in any way be requested and/or claimed again and the user's right to the free one-to-one lesson will be deemed forfeited.</p>
                                <p>At the end of the trial week, the cost of the first month's subscription to the Golden Ribbon course and the Subscription fee as indicated on the Belford Woods School website will be charged to the credit card pre-entered by the user.</p>
                                <p>If the payment fails, the user will no longer be able to access the personal area and continue to use the Platform's services.</p>
                                <p>Once the payment for the first month of subscription and the subscription fee has been correctly recorded, the user will be able to continue their course.</p>
                                <p>The Units are progressively unlocked through the completion of the previous Unit.</p>
                                <p>Each month the user can book up to a maximum of one individual lesson per week up to a maximum of two individual lessons per month. The user can also book up to a maximum of one place in one group lesson per week, up to a maximum of one group lesson per week and two group lessons per month. For the booking procedure the user must peremptorily use the calendar available in their personal area. Reservations through other means are not permitted, nor bookings for third parties.</p>
                                <p>The mid-level test is considered successfully passed when 60% correct answers are obtained.</p>
                                <p>The final test can be booked after completing all Units. The exam can be booked through the calendar available in the user's personal area. A dedicated teacher will follow the user during the test.</p>
                                <p>The monthly course fee is paid by credit card on a 30-days calendar basis. If the payment fails, the user will lose access to their personal area.</p>

                                <p>For Blue Ribbon users, in the first 7-day free trial week, upon first accessing the personal area, the user will be able to view the first Unit and will be able to follow it until completion which will automatically unlock the second Unit. During the free 7-day trial week, the user will only have access to two Units.</p>
                                <p> At the end of the free trial week, the credit card provided at the time of registration by the user will be charged the cost of the first month of the course and the subscription fee as indicated on the Belford Woods School website.</p>
                                <p>If the payment fails, the user will not be able to access their personal area.</p>
                                <p> Once the payment of the Subscription fee and the cost of the first month of subscription has been correctly registered, the user can continue to use the platform.</p>
                                <p>The user can at any time upgrade their subscription and move to the Golden Ribbon subscription.</p>
                                <p>The mid-level test is considered successfully passed when 60% correct answers are obtained.</p>
                                <p>The final test can be booked through the calendar available in your personal area.</p>
                                <p>The user has neither individual lessons nor group lessons available.</p>
                                <p>The monthly subscription fee is paid on a monthly basis using the credit card provided by the user. If for any reason the payment is not successful, the user will lose access to their reserved area.</p>
                                <p>Subscription Fees for both Golden Ribbon and Blue Ribbon users are a one-time fee and will only be charged at the time of initial registration. In case of an upgrade by a user from Blue Ribbon to Golden Ribbon, no Subscription Fees will be required.</p>
                                <p>Blue and Golden Ribbon users may suspend the service at any time. Suspension will result in the interruption of the monthly fee charge starting from the month following the month of the last payment. During suspension, it will not be possible to view the personal area unless the service is reactivated using the credentials previously entered. The suspension may last for a maximum period of six (6) months, at the end of which the user's account will be deleted if it is not reactivated.</p>
                                <p>Golden Ribbon and Blue Ribbon users can withdraw independently and at any time from the Platform and will have the services included in the respective levels available until the end of the monthly payment paid.</p>
                                <p> It is your responsibility to provide a valid email address to which you have access so you can view all communications and any links to the lessons.</p>
                                <p>If a Golden Ribbon or Blue Ribbon user engages in behavior that is offensive, vulgar or in any way aggressive towards teachers, other users or the school itself, Belford Woods School reserves the right to expel such users immediately. In the event of the exclusion of user, no amount will be refunded to that user by Belford Woods School.
                                </p>
                                <h3>IT – Regole della piattaforma</h3>
                                <p>Per “Piattaforma” si intende la parte riservata agli studenti che si registrano sul sito attraverso l’apposito modulo contenente l’intero corso di inglese relativo al livello selezionato dallo studente.</p>
                                <p>Con il termine “Unit” si intende il modulo formativo su diversi livelli e comprendente elementi di grammatica, lessico, scrittura e lettura.</p>
                                <p>Per gli utenti Golden Ribbon, nella prima settimana gratuita di prova di 7 giorni di calendario, al momento del primo accesso l’utente può visualizzare la prima Unit del livello prescelto. Al completamento della prima Unit, la seconda si sblocca automaticamente. Durante la settimana gratuita di prova di 7 giorni di calendario, l’utente avrà accesso solo a due Units. Nella prima settimana gratuita di prova, l’utente può prenotare una lezione individuale gratuita con un insegnante dedicato che dovrà essere tenuta entro la settimana di prova stessa. Laddove l’utente non si prenoti la lazione individuale entro il termine sopra indicato, la lezione gratuita non potrà in alcun modo essere più richiesta e/o reclamata e il diritto dell’utente alla lezione individuale gratuita sarà considerato decaduto.</p>
                                <p>Al termine della settimana di prova, sulla carta di credito preinserita dall’utente sarà addebitato il costo del primo mese di abbonamento al corso Golden Ribbon e la subscription fee (“Subscription Fees”) così come indicati sul sito della Belford Woods School.</p>
                                <p>Qualora il pagamento non si perfezioni, l’utente non potrà più accedere all’area personale e continuare ad usufruire dei servizi della Piattaforma.</p>
                                <p>Una volta che il pagamento del primo mese di abbonamento e della Subscription Fees sarà correttamente registrato, l’utente potrà continuare il proprio corso.</p>
                                <p>Le Unit si sbloccano progressivamente attraverso il completamento della Unit precedente.</p>
                                <p>Ogni mese l’utente può prenotare fino a un massimo di una lezione individuale a settimana e fino a un massimo di due lezioni individuali al mese. L’utente può inoltre prenotare fino a un massimo di un posto in una lezione di gruppo a settimana, fino a un massimo di una lezione di gruppo a settimana e due lezioni di gruppo al mese. Per la procedura di prenotazione l’utente deve perentoriamente usare il calendario che trova disponibile nella propria area riservata. Non sono ammesse prenotazioni attraverso altri mezzi, né prenotazioni per conto terzi.</p>
                                <p>Il test di metà livello si considera superato con successo all’ottenimento del 60% di risposte corrette.</p>
                                <p>Il test finale si può prenotare al completamento di tutte le Unit del livello. L’ora di esame si può prenotare attraverso il calendario disponibile nell’area riservata dell’utente. Un insegnante dedicato seguirà l’utente durante la prova.</p>
                                <p>Il pagamento della quota mensile del corso avviene attraverso carta di credito con cadenza 30 giorni di calendario. Se il pagamento non dovesse perfezionarsi, l’utente perderà l’accesso alla propria area riservata.</p>

                                <p>Per gli utenti Blue Ribbon, nella prima settimana gratuita di prova di 7 giorni di calendario, al momento del primo accesso all’area riservata, l’utente potrà visualizzare la prima Unit e potrà seguirla fino al completamento, che sbloccherà automaticamente la seconda Unit. Durante la settimana gratuita di prova di 7 giorni di calendario, l’utente avrà accesso solo a due Units.</p>
                                <p>Al termine della settimana gratuita di prova, sulla carta di credito fornita al momento della registrazione da parte dell’utente sarà addebitato il costo del primo mese di corso e la Subscription Fees così come indicati sul sito della Belford Woods School.</p>
                                <p>Qualora il pagamento non si perfezionasse, l’utente non potrà accedere alla propria area riservata.</p>
                                <p>Una volta registrato correttamente il pagamento della Subscription Fees e del costo del primo mese di abbonamento, l’utente potrà continuare ad utilizzare la piattaforma.</p>
                                <p>L’utente può in qualsiasi momento procedere all’upgrade del proprio abbonamento e passare alla fascia Golden Ribbon.</p>
                                <p>Il test di metà livello si considera superato con successo all’ottenimento del 60% di risposte corrette.</p>
                                <p>Il test finale si potrà prenotare attraverso il calendario inserito nella propria area riservata.</p>
                                <p>L’utente non ha a disposizione né lezioni individuali né lezioni di gruppo.</p>
                                <p>Il pagamento della quota mensile di abbonamento avviene con cadenza mensile mediante l’impiego della carta di credito fornita dall’utente. Se per qualsiasi ragione il pagamento non dovesse avere successo, l’utente perderà l’accesso alla propria area riservata.</p>

                                <p>La Subscription Fees, sia per gli utenti Golden Ribbon che per gli utenti Blue Ribbon è da considerarsi una tantum e sarà detratta solo al momento della prima iscrizione. In caso di upgrade da parte di un utente da Blue Ribbon a Golden Ribbon, nessuna Subscription Fees sarà richiesta.</p>
                                <p>Gli utenti Blue e Golden Ribbon possono sospendere in qualsiasi momento il servizio. La sospensione comporterà l’interruzione dell’addebito della fees mensile a partire dal mese successivo a quello dell’ultimo pagamento. Durante la sospensione non sarà possibile visualizzare l’area personale se non riattivando il servizio mediante le credenziali precedentemente inserite. La sospensione potrà durare per un periodo massimo di sei (6) mesi, al termine del quale, in assenza di riattivazione, l’account dell’utente sarà eliminato. </p>
                                <p>Gli utenti Golden Ribbon e Blue Ribbon possono recedere autonomamente e in qualsiasi momento dalla piattaforma e avranno a disposizione i servizi inclusi nei rispettivi livelli fino al termine della mensilità pagata. </p>
                                <p>È responsabilità dell’utente fornire un indirizzo di posta elettronica valido e a cui abbia accesso così da poter visualizzare tutte le comunicazioni e gli eventuali link alle lezioni. </p>
                                <p>Qualora l’utente Golden Ribbon o Blue Ribbon tenesse comportamenti offensivi, volgari o in qualsiasi modo aggressivi nei confronti degli insegnanti, di altri utenti o della scuola stessa, la Belford Woods School si riserva il diritto di espellere tali utenti immediatamente. In caso di espulsione di un utente nessun importo sarà rimborsato a quest’ultimo da parte della Belford Woods School.  </p>

                                <h3>FR – Règles de la plateforme</h3>
                                <p>Par "Plateforme", nous entendons la partie réservée aux étudiants qui s'inscrivent sur le site à l'aide du formulaire approprié et qui contient l'intégralité du cours d'anglais pour le niveau choisi par l'étudiant.</p>
                                <p>Le terme "Unité" désigne le module de formation à différents niveaux et comprenant des éléments de grammaire, de vocabulaire, d'écriture et de lecture.</p>
                                <p>Pour les utilisateurs du Golden Ribbon, pendant la première semaine d'essai gratuit de 7 jours, lors de la première connexion, l'utilisateur peut voir la première Unité du niveau choisi. Une fois la première Unité terminée, la seconde est automatiquement déverrouillée. Pendant la semaine d'essai gratuite de 7 jours, l'utilisateur n'aura accès qu'à deux Unités. Lors de la première semaine d'essai gratuite, l'utilisateur peut réserver un cours individuel gratuit avec un professeur dédié qui doit avoir lieu pendant la même semaine d'essai. Si l'utilisateur ne réserve pas la leçon individuelle dans le délai mentionné, la leçon gratuite ne peut en aucun cas être demandée et/ou réclamée à nouveau et le droit de l'utilisateur à la leçon individuelle gratuite sera considéré comme perdu.</p>
                                <p>A la fin de la semaine d'essai, le coût du premier mois d'abonnement au cours Golden Ribbon et les frais d'abonnement tels qu'indiqués sur le site Internet de Belford Woods School seront débités de la carte de crédit pré-saisie par l'utilisateur.</p>
                                <p>En cas d'échec du paiement, l'utilisateur ne pourra plus accéder à l'espace personnel et continuer à utiliser les services de la Plateforme.</p>
                                <p>Une fois le paiement du premier mois d’abonnement et les frais d’abonnement correctement enregistrés, l’utilisateur pourra poursuivre son cours.</p>
                                <p>Les Unités sont progressivement débloquées jusqu'à la fin de l'Unité précédente.</p>
                                <p>Chaque mois, l'utilisateur peut réserver jusqu'à un maximum d'une leçon individuelle par semaine jusqu'à un maximum de deux leçons individuelles par mois. L'utilisateur peut également réserver jusqu'à un maximum d'une place dans un cours collectif par semaine, dans la limite d'un cours collectif par semaine et de deux cours collectifs par mois. Pour la procédure de réservation, l'utilisateur doit impérativement utiliser le calendrier disponible dans son espace réservé. Les réservations par d'autres moyens ne sont pas autorisées, ni les réservations pour des tiers.</p>
                                <p>Le test de niveau intermédiaire est considéré comme réussi lorsque 60 % de réponses correctes sont obtenues.</p>
                                <p>Le test final peut être réservé pour toutes les Unités du niveau. L'heure de l'examen peut être réservée via le calendrier disponible dans la zone réservée de l'utilisateur. Un professeur dédié suivra l'utilisateur pendant le test.</p>
                                <p>Les frais mensuels de cours sont payés par carte de crédit sur une base de 30 jours calendaires. En cas d'échec du paiement, l'utilisateur perdra l'accès à son espace réservé.</p>

                                <p>Pour les utilisateurs du Blue Ribbon, pendant la première semaine d'essai gratuit de 7 jours, lors du premier accès à la zone réservée, l'utilisateur pourra voir la première Unité et pourra la suivre jusqu'à la fin, ce qui débloquera automatiquement la deuxième Unité. Pendant la semaine d'essai gratuite de 7 jours, l'utilisateur n'aura accès qu'à deux Unités.</p>
                                <p>A la fin de la semaine d'essai gratuit, la carte de crédit fournie au moment de l'inscription par l'utilisateur sera débitée du coût du premier mois de cours et des frais d'abonnement comme indiqué sur le site Internet de Belford Woods School.</p>
                                <p>En cas d'échec du paiement, l'utilisateur ne pourra pas accéder à son espace réservé.</p>
                                <p>Une fois le paiement des frais d'abonnement et le coût du premier mois d'abonnement correctement enregistrés, l'utilisateur peut continuer à utiliser la plateforme.</p>
                                <p>L'utilisateur peut à tout moment mettre à niveau son abonnement et passer au groupe Golden Ribbon.</p>
                                <p>Le test de niveau intermédiaire est considéré comme réussi lorsque 60 % de réponses correctes sont obtenues.</p>
                                <p>Le test final peut être réservé via le calendrier inséré dans votre espace réservé.</p>
                                <p>L’utilisateur ne dispose ni de cours individuels ni de cours collectifs.</p>
                                <p>Les frais d'abonnement mensuels sont payés mensuellement à l'aide de la carte de crédit fournie par l'utilisateur. Si, pour une raison quelconque, le paiement échoue, l'utilisateur perdra l'accès à son espace réservé.</p>

                                <p>Les frais d'abonnement pour les utilisateurs du Golden Ribbon et du Blue Ribbon sont forfaitaires et ne seront déduits qu'au moment de l'inscription initiale. Si un utilisateur passe du Blue Ribbon au Golden Ribbon, il n'aura pas à payer de frais d'abonnement.</p>
                                <p>Les utilisateurs du Golden Ribbon et du Blue Ribbon peuvent suspendre le service à tout moment. La suspension entraîne l'interruption de la redevance mensuelle à partir du mois suivant celui du dernier paiement. Pendant la suspension, il ne sera pas possible de consulter l'espace personnel, à moins de réactiver le service à l'aide des données d'identification introduites précédemment. La suspension peut durer une période maximale de six (6) mois, à l'issue de laquelle le compte de l'utilisateur sera supprimé s'il n'est pas réactivé.</p>
                                <p>Les utilisateurs Golden Ribbon et Blue Ribbon peuvent se retirer indépendamment et à tout moment de la Plateforme et disposeront des services inclus dans les niveaux respectifs jusqu'à la fin du paiement mensuel payé.</p>
                                <p>Il est de votre responsabilité de fournir une adresse e-mail valide à laquelle vous avez accès afin que vous puissiez consulter toutes les communications et tous les liens vers les leçons.</p>
                                <p>Si un utilisateur du Golden Ribbon ou du Blue Ribbon adopte un comportement offensant, vulgaire ou agressif de quelque manière que ce soit envers les enseignants, les autres utilisateurs ou l'école elle-même, Belford Woods School se réserve le droit d'expulser immédiatement ces utilisateurs. En cas d'expulsion d'un utilisateur, aucun montant ne sera remboursé à cet utilisateur par Belford Woods School.</p>
                                <h3>ES – Reglas de la plataforma</h3>
                                <p>Por "Plataforma" se entiende la parte reservada a los estudiantes que se inscriben en el sitio a través del formulario que contiene todo el curso de inglés para el nivel seleccionado por el estudiante.</p>
                                <p>El término "Unidad" se refiere al módulo de formación en diferentes niveles y que incluye elementos de gramática, vocabulario, escritura y lectura.</p>
                                <p>Para los usuarios de Golden Ribbon, en la primera semana de prueba gratuita de 7 días, al iniciar sesión por primera vez, el usuario puede ver la primera unidad del nivel elegido. Al completar la primera Unidad, la segunda se desbloquea automáticamente. Durante la semana de prueba gratuita de 7 días, el usuario sólo tendrá acceso a dos Unidades. En la primera semana de prueba gratuita, el usuario puede reservar una lección individual gratuita con un profesor dedicado que debe realizarse dentro de la misma semana de prueba. Si el usuario no reserva la clase individual dentro del plazo mencionado, la clase gratuita no podrá solicitarse ni reclamarse de nuevo y el derecho del usuario a la clase individual gratuita se considerará perdido.</p>
                                <p>Al final de la semana de prueba, el costo de la suscripción del primer mes al curso Golden Ribbon y la tarifa de suscripción indicada en el sitio web de Belford Woods School se cargarán a la tarjeta de crédito ingresada previamente por el usuario.</p>
                                <p>Si el pago falla, el usuario ya no podrá acceder al área personal y seguir utilizando los servicios de la Plataforma.</p>
                                <p>Una vez registrado correctamente el pago del primer mes de suscripción y la cuota de suscripción, el usuario podrá continuar con su curso.</p>
                                <p>Las Unidades se desbloquean progresivamente al completar la Unidad anterior.</p>
                                <p>Cada mes el usuario puede reservar hasta un máximo de una lección individual por semana hasta un máximo de dos lecciones individuales por mes. El usuario también puede reservar hasta un máximo de una plaza en una lección grupal por semana, hasta un máximo de una lección grupal por semana y dos lecciones grupales por mes. Para el procedimiento de reserva el usuario deberá utilizar obligatoriamente el calendario disponible en su área reservada. No se permiten reservas por otros medios, ni reservas para terceros.</p>
                                <p>La prueba de nivel medio se considera superada con éxito cuando se obtiene un 60% de respuestas correctas.</p>
                                <p>La prueba final se puede reservar para todas las unidades del nivel. La hora del examen se podrá reservar a través del calendario disponible en el área reservada del usuario. Un profesor dedicado seguirá al usuario durante la prueba.</p>
                                <p>La cuota mensual del curso se paga con tarjeta de crédito cada 30 días de calendario. Si el pago falla, el usuario perderá el acceso a su área reservada.</p>

                                <p>Para los usuarios de Blue Ribbon, en la primera semana de prueba gratuita de 7 días, al acceder por primera vez al área reservada, el usuario podrá ver la primera Unidad y podrá seguirla hasta su finalización, lo que desbloqueará automáticamente la segunda Unidad. Durante la semana de prueba gratuita de 7 días, el usuario sólo tendrá acceso a dos Unidades.</p>
                                <p>Al finalizar la semana de prueba gratuita, se cargará en la tarjeta de crédito proporcionada en el momento de la inscripción por parte del usuario el coste del primer mes del curso y la cuota de suscripción tal y como se indica en la web de Belford Woods School.</p>
                                <p>Si el pago falla, el usuario no podrá acceder a su área reservada.</p>
                                <p>Una vez registrado correctamente el pago de la cuota de Suscripción y el coste del primer mes de suscripción, el usuario podrá seguir utilizando la plataforma.</p>
                                <p>El usuario puede en cualquier momento actualizar su suscripción y pasar al tipo  Golden Ribbon.</p>
                                <p>La prueba de nivel medio se considera superada con éxito cuando se obtiene un 60% de respuestas correctas.</p>
                                <p>La prueba final podrás reservarla a través del calendario insertado en tu área reservada.</p>
                                <p>El usuario no tiene disponibles lecciones individuales ni lecciones grupales.</p>
                                <p>La tarifa de suscripción mensual se paga mensualmente mediante la tarjeta de crédito dada por el usuario. Si por cualquier motivo el pago no se realiza correctamente, el usuario perderá el acceso a su área reservada.</p>

                                <p>Las tarifas de suscripción para los usuarios de Golden Ribbon y Blue Ribbon se pagan una sola vez y sólo se deducen en el momento del registro inicial. En caso de que un usuario pase de Blue Ribbon a Golden Ribbon, no tendrá que abonar ninguna cuota de suscripción.</p>
                                <p>Los usuarios de Blue Ribbon y Golden Ribbon pueden suspender el servicio en cualquier momento. La suspensión supondrá la interrupción del cobro de la cuota mensual a partir del mes siguiente al del último pago. Durante la suspensión, no será posible visualizar el área personal a menos que se reactive el servicio utilizando las credenciales previamente introducidas. La suspensión podrá durar un periodo máximo de seis (6) meses, al término del cual se eliminará la cuenta del usuario si no se reactiva.</p>
                                <p>Los usuarios de Golden Ribbon y Blue Ribbon podrán retirar de forma independiente y en cualquier momento de la Plataforma y tendrán disponibles los servicios incluidos en los respectivos niveles hasta el final del pago mensual pagado.</p>
                                <p>Es su responsabilidad proporcionar una dirección de correo electrónico válida a la que tenga acceso para poder ver todas las comunicaciones y cualquier link a las lecciones.</p>
                                <p>Si un usuario de Golden Ribbon o Blue Ribbon tiene un comportamiento offensivo, vulgar o de alguna manera agresivo hacia los maestros, otros usuarios o la escuela misma, Belford Woods School se reserva el derecho de expulsar a dichos usuarios de inmediato. En caso de expulsión de un usuario, Belford Woods School no reembolsará ninguna cantidad a dicho usuario.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Regulations