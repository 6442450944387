import React from "react";
import Body from "./common/Body";

function PrivacyPolicy() {
  return (
    <Body>
      <section
        className="belford-calendar-hero-section"
        id="belford-calendar-hero-section"
      >
        <div className="container">
          <div className="row gy-4 justify-content-center align-items-center">
            <div className="col">
              <div className="belford-register-box text-center">
                <h1>
                  <span style={{ color: "#fff" }}>Privacy Policy</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="belford-calendar-table-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12"></div>
          </div>
        </div>
      </section>
    </Body>
  );
}

export default PrivacyPolicy;
