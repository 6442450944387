import React, { useEffect, useState } from "react";
import Body from "./common/Body";
// import img1 from '../assets/images/img-1.png'
import img2 from '../assets/images/img-2.png'
import img3 from '../assets/images/img-3.png'
import img4 from '../assets/images/img-4.png'
import img8 from '../assets/images/img-8.png'
import { useLocation } from "react-router-dom";

const MiddleTestResult = () => {
    const { state } = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {
            if (window.scrollY >= 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);
    return (
        <Body>
            <section className="belford-resuit-hero-section" id="belford-resuit-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="belford-resuit-hero-content text-center">
                                <h1>YOUR ENGLISH LEVEL IS</h1>
                                <h1>YOUR SCORE IS </h1>
                                <h2>{state !== null && state.correct_answer_count}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="belford-resuit-section" id="belford-shop-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="text-center">
                            <h2>Start now your English course!</h2>
                        </div>
                        <div className="col-12 col-lg-9">
                            <div className="belford-journey-btn">
                                <a href="https://belfordwoodsschool.com/register" className="belford-btn">SUBSCRIBE NOW Golden Ribbon</a>
                                <a href="https://belfordwoodsschool.com/register" className="belford-btn belford-btn-primary">SUBSCRIBE NOW Blue Ribbon</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <footer className="result-footer">
                <div className="belford-footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <div className="belford-footer-bottom-img">
                                    <div className="belford-footer-bottom-img">
                                        <a href="/"><img src={img8} className="img-fluid" alt='img8' /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <div className="belford-footer-bottom-social-icon">
                                    <ul>
                                        <li><a href="/"><img src={img2} className="img-fluid" alt='img2' /></a></li>
                                        <li><a href="/"><img src={img3} className="img-fluid" alt='img3' /></a></li>
                                        <li><a href="/"><img src={img4} className="img-fluid" alt='img4' /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="belford-footer-bottom-content justify-content-lg-end">
                                    <ul>
                                        <li><a href="/">Privacy Policy</a></li>
                                        <li><a href="/">Terms and Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="belford-footer-copy-right">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="belford-footer-site-info text-center">
                                    <p>© <a href="/">Belford Woods School</a> Registered Trademark 2023 P.I.17343501007 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Body>
    )
}

export default MiddleTestResult