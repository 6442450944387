import React from 'react'
import Body from './common/Body'
import imgfounder2 from "../assets/images/img-founder-2.png"
import { useTranslation } from 'react-i18next'

const Canceled = () => {
    const { t } = useTranslation()
    return (
        <Body>
            <section className="belford-founder-hero-section" id="belford-founder-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col-lg-12 align-items-cente ">
                            <div className="belford-register-box text-center">
                                <h1> <span>Canceled</span> </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-founder-about-section" id="belford-founder-about-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9  text-center">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#d41717" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                                </svg>
                            </div>
                            <h2>Canceled</h2>   
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Canceled