import React from 'react'
import Body from './common/Body'
import imgitalian1 from "../assets/images/img-italian-1.png"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Italian = () => {
    const { t } = useTranslation();
    return (
        <Body>
            <section className="belford-italian-hero-section" id="belford-italian-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col">
                            <div className="belford-italian-box text-center">
                                <h1>{t("ItalianData.heroSection.H1Text")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-italian-about-section" id="belford-italian-about-section">
                <div className="container">
                    <div className="row justify-content-center gy-4 align-items-center">
                        <div className="col-lg-6">
                            <div className="italian-about-image text-center">
                                <img src={imgitalian1} alt='imgitalian1' />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="italian-about-content">
                                <p>{t("ItalianData.aboutSection.PText")}</p>
                                <Link to="/contact" className="belford-btn">{t("ItalianData.aboutSection.belfordBtn.LinkText")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Italian