import React from 'react'
import Body from './common/Body'

const Reserve = () => {
    return (
        <Body>
            <section className="belford-withdraw-hero-section belford-reserve-hero" id="belford-withdraw-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="belford-withdraw-hero-content text-center">
                                <ul className="belford-tab-bar">
                                    <li> <a href="/" className="active">Welcome</a> </li>
                                    <li> <a href="/">Units</a> </li>
                                    <li> <a href="/">Calendar</a> </li>
                                    <li> <a href="/">Regulations</a> </li>
                                    <li> <a href="/">Contact Us</a> </li>
                                </ul>
                                <h1><span className="belford-title-color">Reserve Your Final Test </span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="belford-withdraw-about-section" id="belford-withdraw-about-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9">
                            <div className="belford-withdraw-about-content text-center">
                                <p>This button as above indicated should always be visible on the Units page but in grey. It should unlock only upon completion of all Units. Both types of subscribers can click on it and get to the calendar as shown above. They can book an hour for the final exam. The school has to receive a notification saying that a final exam has been booked, in order to highlight that it is not a regular className. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Reserve