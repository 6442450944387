import React from 'react'
import Body from './common/Body'
import imgupgrade1 from "../assets/images/img-upgrade-1.png"

const Upgrade = () => {
    return (
        <Body>
            <section className="belford-upgrade-hero-section belford-withdraw-golden" id="belford-upgrade-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <div className="belford-upgrade-hero-content text-center">
                                <ul className="belford-tab-bar">
                                    <li><a href="/" className="active">Welcome</a></li>
                                    <li><a href="/">Units</a></li>
                                    <li><a href="/">Calendar</a></li>
                                    <li><a href="/">Regulations</a></li>
                                    <li><a href="/">Contact Us</a></li>
                                </ul>
                                <h1><span className="belford-title-color">Upgrade</span></h1>
                                <p>€140,00 per month</p>
                                <a href="https://belfordwoodsschool.com/register" className="belford-btn">Upgrade Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-upgrade-about-section" id="belford-upgrade-about-section">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-image-upgrade">
                                <img src={imgupgrade1} className="img-fluid" alt="imgupgrade1" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-list">
                                <h2>What you will get</h2>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i>Complete access to the Platform.</li>
                                    <li><i className="bi bi-check-circle"></i>Two individual classes per month with English or bilingual teacher.</li>
                                    <li><i className="bi bi-check-circle"></i>Two small group classes per month with professional teachers. </li>
                                    <li><i className="bi bi-check-circle"></i>24/7 assistance</li>
                                    <li><i className="bi bi-check-circle"></i>You will start from where you leave now, you will not lose the work completed till now!</li>
                                </ul>
                                <a href="/https://belfordwoodsschool.com/register" className="belford-btn">Upgrade Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Upgrade