import React from "react";
import { privateRoute } from "./../../Routes/allRoutes";
import { NavLink } from "react-router-dom";

const PrivateMenu = () => {
  return (
    <ul className="belford-tab-bar">
      {privateRoute.map((route, index) => (
        <li key={index}>
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "active" : ""
            }
            to={route.path}
          >
            {route.label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default PrivateMenu;
