/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import Body from './common/Body'
import { useForm } from "react-hook-form";
import { API } from '../helper/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const api = new API();

const countries = [
    "Abu Dhabi",
    "Aden",
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Antarctica",
    "Antigua",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Antarctica Territory",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "British West Indies",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Canal Zone",
    "Canary Island",
    "Cape Verdi Islands",
    "Cayman Islands",
    "Chad",
    "Channel Island Uk",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Island",
    "Colombia",
    "Comoro Islands",
    "Congo",
    "Congo Kinshasa",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czech Republic",
    "Dahomey",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Dubai",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Faeroe Islands",
    "Falkland Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "Ifni",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Korea",
    "Korea, South",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Leeward Islands",
    "Lesotho",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Melanesia",
    "Mexico",
    "Moldovia",
    "Monaco",
    "Mongolia",
    "Morocco",
    "Mozambique",
    "Myanamar",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "Netherlands Antilles Neutral Zone",
    "New Caladonia",
    "New Hebrides",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norfolk Island",
    "Norway",
    "Oman",
    "Other",
    "Pacific Island",
    "Pakistan",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Portuguese Timor",
    "Puerto Rico",
    "Qatar",
    "Republic Of Belarus",
    "Republic Of South Africa",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Ryukyu Islands",
    "Sabah",
    "San Marino",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Somaliliand",
    "South Africa",
    "South Yemen",
    "Spain",
    "Spanish Sahara",
    "Sri Lanka",
    "St. Kitts And Nevis",
    "St. Lucia",
    "Sudan",
    "Surinam",
    "Sw Africa",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Tonga",
    "Trinidad",
    "Tunisia",
    "Turkey",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay"
]


const Register = () => {
    const { t, i18n } = useTranslation();
    const { handleSubmit, register, formState: { errors }, getValues } = useForm();
    const [courses, setCourses] = useState([]);
    const [levels, setLevels] = useState([]);
    const navigate = useNavigate();
    const [formError, setFromError] = useState("")

    useEffect(() => {
        getCourses()
        getLevel()
    }, [])

    const getCourses = async () => {
        try {
            const response = await api.get('COURSES');
            if (response.status === true) {
                setCourses(response.data)
            }
        } catch (error) {
            console.error('Error while changing password', error);
            toast.error(error.data.message);
        }
    }

    const getLevel = async () => {
        try {
            const response = await api.get('LEVELS');
            if (response.status === true) {
                setLevels(response.data)
            }
        } catch (error) {
            console.error('Error get Level', error);
            toast.error(error.data.message);
        }
    }

    const onSubmit = async (values) => {
        // const payload = {
        //     "order": {
        //         "items": [
        //             {
        //                 "reference": "demo",
        //                 "name": "demo",
        //                 "quantity": 0.1,
        //                 "unit": "string",
        //                 "unitPrice": 0,
        //                 "taxRate": 15*100,
        //                 "taxAmount": 15*100,
        //                 "grossTotalAmount": 15*100,
        //                 "netTotalAmount": 15*100
        //             }
        //         ],
        //         "amount": 15*100,
        //         "currency": "USD"
        //     },
        //     "checkout": {
        //          "url" : "http://localhost:3000",
        //          "termsUrl": "http://google.com"
        //     }
        // };
        // const response = await api.paymentPost('PAYMENT_URL',payload);
        // console.log(response);
        // console.log(response['paymentId']);
        // if(response['paymentId']){
        //     var checkoutOptions = {
        //         checkoutKey: "test-checkout-key-aaea7ae277f84c2da14da95c0c28d31b",
        //         paymentId : response['paymentId'],
        //         containerId : "checkout-container-div",
        //         language: "en-GB",
        //         theme: {
        //             buttonRadius: "5px"
        //         }
        //     };
        //     var checkout = new Dibs.Checkout(checkoutOptions);
        //     console.log(checkout);
        // }
        try {
            values['payment_id'] = 'pending';
            values['priceId'] = 'price_1PDjsJKKYk6l5mQC2VH678x1';
            values['language'] = i18n.language;
            delete values['check'];
            delete values['check1'];
            const response = await api.post('STUDENT_REGISTER', values);
            if (response.status === true) {
                toast.success('Registration has been done successfully.');
                // alert("Registration has been done successfully");
                window.location.href = response.data.stripeUrl;

            }
            else {
                setFromError(response.message)
            }
        } catch (error) {
            console.error('Error while changing password', error);
            toast.error(error);
        }
    }



    return (
        <Body>
            <ToastContainer closeButton={false} limit={1} />
            <section className="belford-register-hero-section belford-form-section" id="belford-register-hero-section">
                <div className="container">
                    <div className="row gy-4 justify-content-center align-items-center">
                        <div className="col">
                            <div className="belford-register-box text-center">
                                <h1> <span>{t("register.title")}</span> </h1>
                                <h3>{t("register.subTitle")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-form-section belford-register-form-section" id="belford-register-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="belford-form-box">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row g-4">
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="first_name" className="form-label">{t("register.firstName.label")}</label>
                                                <input type="text" className="form-control" id="first_name" placeholder={t("register.firstName.label")} aria-invalid={errors.firstname ? "true" : "false"} {...register("firstname", {
                                                    required: "register.firstName.requireError", pattern: {
                                                        value: /^[A-Za-z]+$/,
                                                        message: "register.firstName.patternError"
                                                    }
                                                })} />
                                            </div>
                                            {errors.firstname && <div className="invalid-feedback">{t(errors.firstname.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="last_name" className="form-label">{t("register.lastName.label")}</label>
                                                <input type="text" className="form-control" id="last_name" placeholder={t("register.lastName.label")} aria-invalid={errors.lastname ? "true" : "false"} {...register("lastname", {
                                                    required: "register.lastName.requireError", pattern: {
                                                        value: /^[A-Za-z]+$/,
                                                        message: "register.lastName.patternError"
                                                    }
                                                })} />
                                            </div>
                                            {errors.lastname && <div className="invalid-feedback">{t(errors.lastname.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="email" className="form-label">{t("register.email.label")}</label>
                                                <input type="email" className="form-control" id="email" placeholder="yourmail@gmail.com" aria-invalid={errors.email ? "true" : "false"} {...register("email",
                                                    {
                                                        required: "register.email.requireError",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "register.email.patternError"
                                                        }
                                                    })} />
                                            </div>
                                            {errors.email && <div className="invalid-feedback">{t(errors.email.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="country" className="form-label">{t("register.country.label")}</label>
                                                <select id="country" className="form-select" {...register("country", { required: "register.country.requireError" })} >
                                                    <option value="">{t("register.country.label")}</option>
                                                    {countries.map((country) => {
                                                        return <option value={country} key={country}>{country}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {errors.country && <div className="invalid-feedback">{t(errors.country.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="create-password" className="form-label">{t("register.create_password.label")}</label>
                                                <input type="password" className="form-control" id="create-password" placeholder="********************" aria-label="Create Password" aria-invalid={errors.password ? "true" : "false"} {...register("password", {
                                                    required: "register.create_password.requireError",
                                                    minLength: {
                                                        value: 8,
                                                        message: "register.create_password.requireError"
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/, // At least one uppercase, one lowercase, one number, and one special character
                                                        message: "register.create_password.patternError"
                                                    }
                                                })} />
                                            </div>
                                            {errors.password && <div className="invalid-feedback">{t(errors.password.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="confirm-password" className="form-label">{t("register.confirm_password.label")}</label>
                                                <input type="password" className="form-control" id="confirm-password" placeholder="********************" aria-label="Confirm Password" aria-invalid={errors.password_confirmation ? "true" : "false"} {...register("password_confirmation", { required: "register.confirm_password.requireError", validate: value => value === getValues("password") || "register.confirm_password.validateError" })} />
                                            </div>
                                            {errors.password_confirmation && <div className="invalid-feedback">{t(errors.password_confirmation.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="last_name" className="form-label">{t("register.phone.label")}</label>
                                                <input type="number" className="form-control" id="Phone" placeholder={t("register.phone.label")} aria-invalid={errors.phone ? "true" : "false"} {...register("phone", { required: "register.phone.requireError", maxLength: { value: 10, message: "register.phone.maxError" } })} />
                                            </div>
                                            {errors.phone && <div className="invalid-feedback">{t(errors.phone.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="country" className="form-label">{t("register.select_level.label")}</label>
                                                <select id="Level" className="form-select" aria-invalid={errors.level_id ? "true" : "false"} {...register("level_id", { required: "register.select_level.requireError" })} >
                                                    <option value="">{t("register.select_level.label")}</option>
                                                    {levels?.map((level, i) => (
                                                        <option value={level.id} key={i}>{level.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.level_id && <div className="invalid-feedback">{t(errors.level_id.message)}</div>}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form">
                                                <label htmlFor="country" className="form-label">{t("register.select_package_ribbon.label")}</label>
                                                <select id="Package" className="form-select" aria-invalid={errors.course_id ? "true" : "false"} {...register("course_id", { required: "register.select_package_ribbon.requireError" })}>
                                                    <option value="">{t("register.select_package_ribbon.label")}</option>
                                                    {courses?.map((course, i) => (
                                                        <option value={course.id} key={i}>{course.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.course_id && <div className="invalid-feedback">{t(errors.course_id.message)}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-input" type="checkbox" id="check" {...register("check", { required: "register.checkobx1.requireError" })} />
                                                <label className="form-label" htmlFor="check">{t("register.checkobx1.label1")} <Link to="/terms-and-condition" class="register-link">{t("register.checkobx1.linkText")}</Link> {t("register.checkobx1.label2")}</label>
                                            </div>
                                            {errors.check && <div className="invalid-feedback">{t(errors.check.message)}</div>}
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-input" type="checkbox" id="check1" {...register("check1", { required: "register.checkobx2.requireError" })} />
                                                <label className="form-label" htmlFor="check1">{t("register.checkobx2.label")} <Link to="/privacy-policy" class="register-link">{t("register.checkobx2.linkText")}</Link></label>
                                            </div>
                                            {errors.check1 && <div className="invalid-feedback">{t(errors.check1.message)}</div>}
                                        </div>
                                        <p className='text-center text-danger'>{formError}</p>
                                        <div className="col-12">
                                            <div className="form text-center">
                                                <button type="submit" className="belford-btn">{t("register.Register")}</button>
                                            </div>
                                        </div>
                                        {/* <div id="checkout-container-div"></div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section
        className="home-page belford-start-now-pricing-section"
        id="belford-start-now-pricing-section"
      >
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-6">
              <div className="belford-start-now-pricing-box start-now-yellow-color">
                <div>
                  <div className="start-now-pricing-title">
                    <h4>{t("pricing_table.golden.title")}</h4>
                  </div>
                  <ul className="start-now-pricing-inner">
                    <li>{t("pricing_table.golden.title")}</li>
                    <li>{t("pricing_table.golden.list1")}</li>
                    <li>{t("pricing_table.golden.list2")}</li>
                    <li>{t("pricing_table.golden.list3")}</li>
                  </ul>
                </div>
                <div className="start-now-pricing-info">
                  <h4>COSTS</h4>
                  <p>{t("pricing_table.golden.list4")}</p>
                  <p>{t("pricing_table.golden.list5")}</p>
                  <p>{t("pricing_table.golden.list6")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="belford-start-now-pricing-box start-now-blue-color">
                <div>
                  <div className="start-now-pricing-title">
                    <h4>{t("pricing_table.blue.title")}</h4>
                  </div>
                  <ul className="start-now-pricing-inner">
                    <li>{t("pricing_table.blue.title")}</li>
                    <li>{t("pricing_table.blue.list1")}</li>
                    <li>{t("pricing_table.blue.list2")}</li>
                  </ul>
                </div>
                <div className="start-now-pricing-info">
                  <h4>COSTS</h4>
                  <p>{t("pricing_table.blue.list3")}</p>
                  <p>{t("pricing_table.blue.list4")}</p>
                  <p>{t("pricing_table.blue.list5")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </Body >
    )
}

export default Register
