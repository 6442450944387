import React from 'react'
import Body from './common/Body'
import imgtranslation2 from "../assets/images/img-translation-2.png"
import { useTranslation } from 'react-i18next';

const Translation = () => {
    const { t } = useTranslation();
    return (
        <Body>
            <section className="belford-translation-hero-section" id="belford-translation-hero-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="belford-translation-box">
                                <h1>{t("translationData.heroSection.H1Text")}</h1>
                                <p>{t("translationData.heroSection.PText")}</p>
                                <a href="https://belfordwoodsschool.com/register" className="belford-btn">{t("translationData.heroSection.belfordbtn.Text")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="belford-translation-about-section" id="belford-translation-about-section">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-image-translation">
                                <img src={imgtranslation2} className="img-fluid" alt="about-img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-title-translation">
                                <h2><span>{t("translationData.aboutSection.aboutTitleTranslation.H2Text.SpanText")}</span>{t("translationData.aboutSection.aboutTitleTranslation.H2Text.Text")}</h2>
                            </div>
                            <div className="about-content">
                                <p>{t("translationData.aboutSection.aboutContent1.PText1")}</p>
                                <p>{t("translationData.aboutSection.aboutContent1.PText2")}</p>
                            </div>
                            <div className="about-translation-btn">
                                <a href="/">{t("translationData.aboutSection.aboutTranslationBtn.Text")}</a>
                            </div>
                            <div className="about-content">
                                <p>{t("translationData.aboutSection.aboutContent2.PText")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Body>
    )
}

export default Translation